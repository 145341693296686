<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <div>
    <MyHeader />
    <main id="content"
          v-if="computedProperty">
      <div class="propSlidehow">
        <flickity class="slidehow"
                  ref="flickity"
                  :options="flickityOptions">
          <div class="img"
               v-for="(image, index) in media"
               :key="index">
            <img class="w-100"
                 @click="openGallery(index)"
                 :data-flickity-lazyload="image.thumb">
          </div>
        </flickity>
        <LightBox v-if="media && media.length > 0"
                  class="slidehow"
                  ref="lightbox"
                  :showLightBox="false"
                  :media="media" />
      </div>
      <div class="container property">
        <div class="row">
          <section class="col-12 col-lg-8">
            <div class="property__basic">
              <h1>
                {{ computedProperty.name }}
                <a class="like"
                   href="javascript:;"
                   @click="toggleFavorites(computedProperty)">
                  <i :class="[isFavoriteComputed ? 'fas' : 'far', 'fa-heart']" /></a>
              </h1>
              <h2 class="location"
                  v-if="computedProperty.country && computedProperty.state">
                {{ computedProperty.state.name }}, {{ computedProperty.country.name }}
              </h2>
              <div class="features">
                <span><i class="far fa-user" />{{ computedProperty.maximum_capacity }} Guests</span>
                <span v-if="computedProperty.bedrooms > 0"><i class="icon-bed" /> {{ computedProperty.bedrooms }} Bedroom{{ property.bedrooms > 1 ? 's' : '' }}</span>
                <span><i class="icon-bath" /> {{ computedProperty.number_bathroom }} Bathroom{{ property.number_bathroom > 1 ? 's' : '' }}</span>
              </div>
              <div class="resume">
                <p v-html="computedProperty.description" />
                <!-- <button @click="readMore = !readMore">
                  <i class="icon-cheveron-down" /> Read {{ readMore ? 'more' : 'less' }}
                </button> -->
              </div>
            </div>
            <div v-if="property.rooms.length > 0 "
                 class="d-block mb-5">
              <h4>Beds</h4>
              <hr>
              <div class="scroll-bed-list">
                <a href="javascript:;"
                   class="button-left"
                   @click="prevScroll()">
                  <i class="icon-cheveron-left" />
                </a>
                <ul class="bedding"
                    id="bedsList">
                  <li v-for="(room, index) in property.rooms"
                      :key="index">
                    <div class="wrap-icons-beds">
                      <i class="icon-one-bed"
                         v-for="(bed, i) in room.beds"
                         :key="i" />
                    </div>
                    <strong>{{ room.room_type }}</strong>
                    <small v-for="(bed, i) in room.beds"
                           class="d-block pb-1"
                           :key="i">{{ bed.bed_quantity }}</small>
                  </li>
                </ul>
                <a href="javascript:;"
                   class="button-right"
                   @click="nextScroll()">
                  <i class="icon-cheveron-right" />
                </a>
              </div>
              <!-- <div class="d-block mb-5"
                   v-if="computedProperty.beds && computedProperty.beds.length > 0">
                <h4>Bedding Distribution</h4>
                <hr>
                <ul class="bedding">
                  <li v-for="(bed, index) in computedProperty.beds"
                      :key="index">
                    <strong>{{ bed.bed_name }} ({{ bed.bed_quantity }})</strong> <small>{{ bed.bed_type }}</small>
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="d-block house-rules"
                 v-if="computedProperty.house_rules && computedProperty.house_rules.length > 0">
              <h4>House Rules</h4>
              <hr>
              <ul class="rules-list">
                <li v-for="(rule, index) in computedProperty.house_rules"
                    :key="index">
                  <i :class="rule.icon" />
                  <!-- <i class="icon-time" /> -->
                  {{ rule.rule }}
                </li>
              </ul>
            </div>
            <div class="d-block amenities"
                 v-if="computedProperty.amenities && computedProperty.amenities.length > 0">
              <h4>Amenities & Features</h4>
              <hr>
              <ul class="check-list"
                  :class="isShort && 'little'">
                <li v-for="(amenity, index) in computedProperty.amenities"
                    :key="index">
                  {{ amenity.description }}
                </li>
              </ul>
              <a href="javascript:;"
                 class="btn btn-primary"
                 @click="toggleAmenities">
                {{ isShort ? 'Read more' : 'Read less' }}
              </a>
            </div>
            <div class="d-block mb-5 experiences">
              <h4 v-if="computedProperty.state && (property.lifestyle || computedProperty.video_embed || computedProperty.state.video_embed)">
                Experiences in {{ property.state.name }}
              </h4>
              <hr>
              <p v-html="property.lifestyle" />
              <div class="embed-responsive embed-responsive-16by9"
                   v-if="computedProperty.video_embed">
                <iframe class="embed-responsive-item"
                        :src="`https://www.youtube.com/embed/${computedProperty.video_embed}`"
                        allowfullscreen />
              </div>
              <div class="embed-responsive embed-responsive-16by9 mt-2"
                   v-if="computedProperty.state && computedProperty.state.video_embed">
                <iframe class="embed-responsive-item"
                        :src="`https://www.youtube.com/embed/${computedProperty.state.video_embed}`"
                        allowfullscreen />
              </div>
            </div>
            <!-- <div class="d-block">
              <h4 v-if="computedProperty.state">
                Experiences in {{ property.state.name }}
              </h4>
              <hr>
              <p v-html="property.lifestyle" />
              <div class="embed-responsive embed-responsive-16by9"
                   v-if="computedProperty.video_embed">
                <iframe class="embed-responsive-item"
                        :src="`https://www.youtube.com/embed/${computedProperty.video_embed}`"
                        allowfullscreen />
              </div>
            </div> -->
            <!-- <div class="d-block"
                 v-if="computedProperty">
              <h4>Around the property</h4>
              <hr>
              <p v-html="property.around_property" />
              <div class="embed-responsive embed-responsive-16by9">
                <GmapMap
                  :center="{ lat: parseFloat(computedProperty.lat), lng: parseFloat(computedProperty.lng) }"
                  :zoom="16"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false
                  }"
                  style="width: 100%; height: 100%;"
                  class="embed-responsive-item">
                  <GmapMarker :position="google && new google.maps.LatLng(parseFloat(computedProperty.lat), parseFloat(computedProperty.lng))"
                              :clickable="true"
                              icon="/img/MarkerMap.png"
                              @click="center=m.position"
                              ref="myMarker" />
                  <GmapMarker v-for="(marker, index) in computedProperty.list_experiencies"
                              :key="index"
                              :position="google && new google.maps.LatLng(parseFloat(marker.lat), parseFloat(marker.long))"
                              :clickable="true"
                              icon="/img/MarkerMap.png"
                              @click="center=m.position"
                              ref="myMarker" />
                </GmapMap>
              </div>
            </div> -->
            <div class="d-block mb-5"
                 v-if="computedProperty && computedProperty.home_policies">
              <h4>Home Policies</h4>
              <hr>
              <div v-html="parseHtml(computedProperty.home_policies)" />
              <p>
                For a complete set of policies for this home, please get in touch with our Travel Advisors.
              </p>
            </div>
            <div class="d-block mb-5"
                 v-if="computedProperty && computedProperty.cancellation_policy">
              <h4>Cancellation Policy</h4>
              <hr>
              <p v-html="computedProperty.cancellation_policy">
                For a complete set of policies for this home, please get in touch with our Travel Advisors.
              </p>
            </div>
          </section>
          <aside class="col-12 col-lg-4 property-aside">
            <form class="plan-trip"
                  @submit.prevent="handlePropertyRequest">
              <h3>Plan Your Trip</h3>
              <hr>
              <p class="price">
                from <strong>${{ (property.live_price ? property.live_price : property.price)| numFormat() }}</strong>/night {{ bookingParams.check_in && bookingParams.check_out ? 'for the selected dates' : '' }}
              </p>
              <div class="form-group">
                <HotelDatePicker
                  :starting-date-value="temporal_check_in"
                  :ending-date-value="temporal_check_out"
                  :disabledDates="disableDatesArray"
                  :key="disableDatesArray.length"
                  :class="temporal_check_in && temporal_check_out && 'active' || bookingParams.check_in && bookingParams.check_out && 'active'"
                  @check-in-changed="setCheckinDate"
                  @check-out-changed="setCheckoutDate" />
              </div>
              <div class="form-group p-0">
                <div class="select">
                  <i class="far fa-user" />
                  <v-select
                    name="guests"
                    v-model="bookingParams.travelers"
                    :options="travelerOptions"
                    placeholder="Travelers" />
                </div>
              </div>
              <button type="submit"
                      class="btn btn-primary btn-block">
                {{ property.instant_book ? 'BOOK NOW' : 'REQUEST BOOK' }}
              </button>
              <!-- <button v-else
                      class="btn btn-primary btn-block"
                      data-toggle="modal"
                      data-target="#loginModal"
                      @click="$store.dispatch('updateSignInModalStatus', true)">
                BOOK NOW
              </button> -->
              <hr>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="error">{{ error }}</span>
              <!-- <div class="learn-more">
                <i class="icon-phone" />
                <p>Call <a href="tel:+18886257525">888-625-7525</a> to learn more.</p>
              </div> -->
            </form>
          </aside>
        </div>
      </div>
      <!-- <div class="bg-light clearfix"
           v-if="computedProperty.similar_properties && computedProperty.similar_properties.length > 0">
        <div class="container mt-5">
          <div class="row">
            <div class="col">
              <h2 class="subtitle mt-3 mt-lg-5 mb-4">
                Similar Properties
              </h2>
            </div>
          </div>
          <PropertyList :properties="computedProperty.similar_properties"
                        :config="{ limit: 4, sort: 'created_at'}" />
        </div>
      </div> -->
      <newsletter />
    </main>
    <MyFooter />
  </div>
</template>

<script>

// eslint-disable-next-line
import Flickity from 'vue-flickity';
import vSelect from 'vue-select';
import HotelDatePicker from 'vue-hotel-datepicker';
import { mapActions, mapGetters } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import moment from 'moment';
import Vue from 'vue';
import VueLazyLoad from 'vue-lazyload';
import LightBox from 'vue-image-lightbox';
import Newsletter from '../components/Newsletter.vue';

require('vue-image-lightbox/dist/vue-image-lightbox.min.css');

Vue.use(VueLazyLoad);

export default {
  name: 'Property',
  components: {
    Flickity,
    vSelect,
    HotelDatePicker,
    Newsletter,
    LightBox
  },
  data() {
    return {
      property: null,
      readMore: true,
      bookingParams: {
        travelers: { label: '1 Travelers', value: 1 },
        check_in: '',
        check_out: '',
        property_id: 0,
      },
      temporal_check_in: null,
      temporal_check_out: null,
      error: '',
      isFavorite: false,
      travelerOptions: [
      ],
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: false,
        lazyLoad: 2,
      },
      disableDatesArray: [],
      media: [],
      isShort: true
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.property ? this.property.name : '',
      // all titles will be injected into this template
      // titleTemplate: '%s | Exclusive experience specialists',
      meta: [
        { name: 'description', content: this.property ? this.property.short_description : '' },
        // Schema.org markup for Google+
        { itemprop: 'name', content: this.property ? this.property.name : '' },
        { itemprop: 'description', content: this.property ? this.property.short_description : '' },
        { itemprop: 'image', content: this.media.length > 0 ? this.media[0].thumb : '' },
        // Twitter Card data
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.property ? this.property.name : '' },
        { name: 'twitter:description', content: this.property ? this.property.short_description : '' },
        // Twitter summary card with large image must be at least 280x150px
        { name: 'twitter:image:src', content: this.media.length > 0 ? this.media[0].thumb : '' },
        // Open Graph data
        { property: 'og:title', content: this.property ? this.property.name : '' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: this.$route.fullPath },
        { property: 'og:image', content: this.media.length > 0 ? this.media[0].thumb : '' },
        { property: 'og:description', content: this.property ? this.property.short_description : '' },
      ]
    };
  },
  methods: {
    ...mapActions([
      'requestProperty',
      'requestBooking',
      'deleteFavoritesProperties',
      'updateFavoritesProperties',
      'updateFavoritesPropertiesLocalStorage',
      'requestFavoritesProperties',
    ]),
    ...mapGetters([
      'getUser',
      'getFavoritesPropertiesLocalStorage',
    ]),
    handlePropertyRequest() {
      this.error = '';
      if (this.bookingParams.check_in && this.bookingParams.check_out) {
        this.requestBooking(this.bookingParams).then(() => {
          this.$router.push({ name: 'user-booking-request' });
        }).catch((error) => {
          this.error = error.response.data.error === 'token_not_provided' ? '' : error.response.data.error;
        });
      } else {
        this.error = 'The dates field is required.';
      }
    },
    setCheckinDate(checkIn) {
      const month = (`0${checkIn && checkIn.getMonth() + 1}`).slice(-2);
      this.bookingParams.check_in = `${checkIn.getFullYear()}-${month}-${checkIn.getDate()}`;
    },
    setCheckoutDate(checkOut) {
      const month = (`0${checkOut && checkOut.getMonth() + 1}`).slice(-2);
      this.bookingParams.check_out = `${checkOut.getFullYear()}-${month}-${checkOut.getDate()}`;
    },
    toggleFavorites(property) {
      if (this.getUser()) {
        this.updateFavoritesProperties(property.id);
      } else {
        this.updateFavoritesPropertiesLocalStorage(property);
      }
      this.isFavorite = !this.isFavorite;
    },
    isEmpty(val) {
      return !!((val === undefined || val == null || val.length <= 0 || val === 'null'));
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    prevScroll() {
      document.getElementById('bedsList').scrollLeft -= 50;
    },
    nextScroll() {
      document.getElementById('bedsList').scrollLeft += 50;
    },
    toggleAmenities() {
      this.isShort = !this.isShort;
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters([
      'getSignInModalStatus',
    ]),
    isLogIn() {
      return this.$store.getters.getUser;
    },
    computedProperty() {
      return this.property;
    },
    isFavoriteComputed() {
      return this.isFavorite;
    },
    disabledDates() {
      return this.disableDatesArray;
    },
    parseHtml() {
      return (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const { textContent } = doc.documentElement;
        return textContent;
      };
    }
  },
  created() {
    if (this.$route.query.travelers) {
      this.bookingParams.travelers = JSON.parse(this.$route.query.travelers);
    }
    if (!this.isEmpty(this.$localStorage.get('check_in')) && !this.isEmpty(this.$localStorage.get('check_out'))) {
      this.temporal_check_in = new Date(this.$localStorage.get('check_in'));
      this.temporal_check_out = new Date(this.$localStorage.get('check_out'));
      this.setCheckinDate(this.temporal_check_in);
      this.setCheckoutDate(this.temporal_check_out);
    }
    this.requestProperty(this.$route.params.id).then((response) => {
      this.property = response.data.data;
      if (this.property.images.length > 0) {
        for (let i = 0; i < this.property.images.length; i += 1) {
          this.media.push({ thumb: this.property.images[i].medium, src: this.property.images[i].original });
        }
      }
      Promise.all([this.getUser() ? this.requestFavoritesProperties() : this.getFavoritesPropertiesLocalStorage()]).then((responseProperties) => {
        const favoriteProperties = responseProperties[0] && responseProperties[0].data ? responseProperties[0].data.data : responseProperties[0];
        if (favoriteProperties && favoriteProperties.length > 0) {
          this.isFavorite = favoriteProperties.some(p => p.id === this.property.id);
        }
      });
      this.bookingParams.property_id = this.property.id;
      for (let i = 0; i < this.property.maximum_capacity; i += 1) {
        this.travelerOptions.push({ label: `${i + 1} Travelers`, value: i + 1 });
      }
      let newDate;
      if (this.property.disabled_dates.length) {
        for (let i = 0; i < this.property.disabled_dates.length; i += 1) {
          newDate = moment(this.property.disabled_dates[i]);
          this.disableDatesArray.push(newDate.add(1, 'days').format('YYYY-MM-DD'));
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.datepicker__wrapper {
  background: #fff;
  position: relative;
  .datepicker__input:first-child {
    padding-left: 57px;
    &::before {
      position: absolute;
      top: auto;
      bottom: auto;
      left: 5px;
      margin-right: 10px;
      font-size: 22px;
      font-family: "Font Awesome 5 Free";
      content: '\f073';
      color: #11568C;
    }
  }
}
.like:hover {
  text-decoration: none;
}
.slidehow {
  .img {
    width: 50%;
    height: 405px
  }
}
.little {
  max-height: 200px !important;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 90px;
    opacity: 0.5;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    left: 0px;
    background: linear-gradient(to bottom, #ffffff -14%, #f2f2f2 245%);
  }
}
.btn {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 20px auto;
}
h1 {
  padding-right: 40px;
}
</style>
